import { Packet,PacketState, QueueWithPacket} from "./types";
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {showNotification} from "../utils/notification";

const initialState: PacketState = {
    packet: null,
    packetsList: [],
}

export const packetSlice = createSlice({
    name: 'packet',
    initialState,
    reducers: {
        setPacketsList: (state, action: PayloadAction<QueueWithPacket[]>) => {
            state.packetsList = action.payload
        },
        setPacket: (state, action: PayloadAction<Packet>) => {
            state.packet = action.payload
        }
    },
})

export const {setPacketsList, setPacket} = packetSlice.actions

export default packetSlice.reducer

export const getPacketsListTC = (organization_id: number | undefined, is_complex: number, queue_id:number | undefined = undefined) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getPacketsList(organization_id, is_complex, queue_id);
        if (result.status === 200) {
            dispatch(setPacketsList(result.data.data.result))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const getPacketTC = (packet_id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getPacket(packet_id);
        if (result.status === 200) {
            dispatch(setPacket(result.data.data.packet))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const setPacketServiceStopListTC = (packet_id:number, internal_id: string, organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.putPacketServiceStopList(packet_id, internal_id);
        if (result.status === 200) {
            let res = await queueApi.getPacketsList(organization_id, 1);
            if (res.status === 200) {
                dispatch(setPacketsList(res.data.data.result))
            } else {
                showNotification('Не верные данные, проверьте запрос')
            }
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const setPacketServiceActiveListTC = (packet_id:number, internal_id: string, organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.putPacketServiceActiveList(packet_id, internal_id);
        if (result.status === 200) {
            let res = await queueApi.getPacketsList(organization_id, 1);
            if (res.status === 200) {
                dispatch(setPacketsList(res.data.data.result))
            } else {
                showNotification('Не верные данные, проверьте запрос')
            }
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}