import {Criterion, CriterionState, Packet, QueueWithPacket} from "./types";
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {showNotification} from "../utils/notification";

const initialState: CriterionState = {
    criterion: null,
    criterionList: [],
}

export const criterionSlice = createSlice({
    name: 'criterion',
    initialState,
    reducers: {
        setCriterionsList: (state, action: PayloadAction<Criterion[]>) => {
            state.criterionList = action.payload
        },
        setCriterion: (state, action: PayloadAction<Criterion>) => {
            state.criterion = action.payload
        }
    },
})

export const {setCriterionsList, setCriterion} = criterionSlice.actions

export default criterionSlice.reducer

export const getCriterionsListTC = (queue_id:number | undefined = undefined) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getCriterionsList(queue_id);
        if (result.status === 200) {
            dispatch(setCriterionsList(result.data.data.result))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const getCriterionTC = (criterion_id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getCriterion(criterion_id);
        if (result.status === 200) {
            dispatch(setCriterion(result.data.data.packet))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}