import {OrganizationUpdate} from "./types";
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {setOrganizationData} from "./authSlice";
import {showNotification} from "../utils/notification";

const initialState: OrganizationUpdate = {
    id: 0,
    title: '',
    title_short: ''
}
export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        updateOrganization: (state, action: PayloadAction<OrganizationUpdate>) => {
            const {title, title_short} = action.payload;
            state.title = title;
            state.title_short = title_short;
        },
    },
});
export const updateOrganizationTC = (id: number, title: string, title_short: string) => async (dispatch: Dispatch) => {
    try {
        let updateResult = await queueApi.putUpdateOrganisation({id, title, title_short});
        dispatch(updateOrganization({id, title, title_short}))

        if (updateResult.status === 200) {
            let getResult = await queueApi.getOrganization(id);

            if (getResult.status === 200) {
                dispatch(setOrganizationData(getResult.data.data.result[0]));
            } else {
                showNotification("Не верные данные, проверьте запрос");
            }
        } else {
            showNotification("Не удалось обновить данные организации");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};
export const {updateOrganization} = organizationSlice.actions;

export default organizationSlice.reducer;