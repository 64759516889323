import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import lg from "../../assets/images/logo.png";
import {LogoState} from "./types";
import {showNotification} from "../utils/notification";

const initialState: LogoState = {
    id: 0,
    title: "default image",
    path: lg,
    organization_id: 0
};

export const logoSlice = createSlice({
    name: "logo",
    initialState,
    reducers: {
        setLogo: (state, action: PayloadAction<LogoState>) => {
            state.id = action.payload.id;
            state.path = action.payload.path;
            state.title = action.payload.title;
            state.organization_id = action.payload.organization_id;
        },
        changeLogo: (state, action: PayloadAction<{path: string}>) => {
            state.path = action.payload.path;
        },
        setCurrentLogoToLocalState: (state, action: PayloadAction<{file: File, title: string}>) => {
            state.title = action.payload.title;
            state.path = action.payload.file;
        }
    }
});

export const {setLogo, changeLogo} = logoSlice.actions;

export default logoSlice.reducer;

export const getOrganizationLogoTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getOrganizationLogo(id);
        if (result.status === 200 && result.data.status) {
            dispatch(setLogo(result.data.data.result[0]));
            return
        } 
        if (result.status === 200 && !result.data.status) {
            return ''
        }
        else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateOrganizationLogoTC = (file: File, organization_id: number, title: string) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.postUpdateLogo({organization_id, file, title});
        if (result.status === 200) {
            dispatch(changeLogo({path: result.data.file.preview}));
        } else {
            showNotification("Ошибка при обновлении логотипа");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};
