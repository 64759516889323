import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {BoardsByQueus, BoardsUpdate, Videos, VideosProgress} from "./types";
import {showNotification, showNotificationSuccess} from "../utils/notification";
import {setQueuesListByQueu} from "./queueSlice";

const initialState: {
    videosList: Array<Videos>;
    videosCounter: number;
    videosUploaded: number;
    videosUploadProgress: Array<VideosProgress>;
} = {
    videosList: [] as Array<Videos>,
    videosCounter: 0,
    videosUploaded: 0,
    videosUploadProgress: [] as Array<VideosProgress>
};

export const videosSlice = createSlice({
    name: "videos",
    initialState,
    reducers: {
        setVideosList: (state, action: PayloadAction<Videos[]>) => {
            state.videosList = action.payload;
        },
        setVideosCounter: (state, action: PayloadAction<number>) => {
            state.videosCounter = action.payload;
        },
        setVideosUploaded: (state) => {
            state.videosUploaded = state.videosUploaded + 1;
        },
        setVideosUploadedReset: (state) => {
            state.videosUploaded = 0;
        },
        setVideosUploadedProgress: (state, action: PayloadAction<VideosProgress>) => {
            const newProgress =
                state.videosUploadProgress.find((el) => el.id === action.payload.id) ||
                ({} as VideosProgress);
            newProgress.progress = action.payload.progress;
            state.videosUploadProgress = state.videosUploadProgress.map((el) => {
                return el.id !== action.payload.id ? el : newProgress;
            });
        },
        setVideosUploadedProgressStart: (state, action: PayloadAction<VideosProgress>) => {
            state.videosUploadProgress = [...state.videosUploadProgress, action.payload];
        },
        setVideosUploadedProgressReset: (state) => {
            state.videosUploadProgress = [] as Array<VideosProgress>;
        }
    }
});

export const {
    setVideosList,
    setVideosCounter,
    setVideosUploaded,
    setVideosUploadedReset,
    setVideosUploadedProgress,
    setVideosUploadedProgressStart,
    setVideosUploadedProgressReset
} = videosSlice.actions;

export default videosSlice.reducer;

export const getVideosTC = (organization_id: number) => async (dispatch: Dispatch) => {
    try {
        const result = await queueApi.getOrganizationVideos(organization_id);
        if (result.status === 200) {
            dispatch(setVideosList(result.data.data.result));
        } else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const deleteVideoTC =
    (id: number, organization_id: number) => async (dispatch: Dispatch) => {
        try {
            let result = await queueApi.deleteVideo(id);
            if (result.status === 200) {
                showNotificationSuccess("Видео успешно удалено");
                const result = await queueApi.getOrganizationVideos(organization_id);
                if (result.status === 200) {
                    dispatch(setVideosList(result.data.data.result));
                } else {
                    showNotification("Не верные данные, проверьте запрос");
                }
            } else {
                showNotification("Нет данных, проверьте запрос");
            }
        } catch (error: any) {
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };

export const updateBoardsTC =
    (id: number, organization_id: number) => async (dispatch: Dispatch) => {
        try {
            let result = await queueApi.deleteVideo(id);
            if (result.status === 200) {
                showNotificationSuccess("Видео успешно удалено");
                const result = await queueApi.getOrganizationVideos(organization_id);
                if (result.status === 200) {
                    dispatch(setVideosList(result.data.data.result));
                } else {
                    showNotification("Не верные данные, проверьте запрос");
                }
            } else {
                showNotification("Нет данных, проверьте запрос");
            }
        } catch (error: any) {
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };

export const uploadBoardVideosTC =
    (file: File, organization_id: number, title: string, id: string) =>
    async (dispatch: Dispatch) => {
        try {
            const result = await queueApi.postOrganizationVideos(
                file,
                organization_id,
                title,
                dispatch,
                id
            );
            if (result.status === 200) {
                dispatch(setVideosUploaded());
                showNotificationSuccess(`Видео ${title} успешно загружено`);
                const result = await queueApi.getOrganizationVideos(organization_id);
                if (result.status === 200) {
                    dispatch(setVideosList(result.data.data.result));
                } else {
                    showNotification("Не верные данные, проверьте запрос");
                }
            } else {
                dispatch(setVideosUploaded());
                showNotification("Не верные данные, проверьте запрос");
            }
        } catch (error: any) {
            dispatch(setVideosUploaded());
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };

export const getQueueListByQueuTC = (queu_id: number) => async (dispatch: Dispatch) => {
    try {
        let res = await queueApi.getQueueListByQueu(queu_id);
        if (res.data.status) {
            const data = res.data.data.result;
            const normalizeData = data.map((el: BoardsByQueus) => {
                return {
                    ...el,
                    videos_ids: el.videos_ids ? el.videos_ids?.split(",") : el.videos_ids
                };
            });
            dispatch(setQueuesListByQueu(normalizeData));
        } else {
            showNotification("Нет данных, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const putUpdateBoardVideoTC =
    (data: BoardsUpdate, isLast: boolean = false) => async (dispatch: Dispatch) => {
        try {
            let res = await queueApi.putUpdateBoardVideo(data);
            if (res.data.status) {
                if (isLast) {
                    showNotificationSuccess("Табло успешно обновлены");
                    dispatch(setVideosUploadedReset());
                }
                return;
            } else {
                if (isLast) {
                    showNotification("Ошибка при обновлении табло, попробуйте ещё раз");
                    dispatch(setVideosUploadedReset());
                }
                return showNotification("Ошибка при обновлении табло, попробуйте ещё раз");
            }
        } catch (error: any) {
            dispatch(setVideosUploadedReset());
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };
