import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {showNotification} from "../utils/notification";
import {Service, ServiceState} from "./types";


const initialState: ServiceState = {
    services:[],
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setService: (state, action: PayloadAction<Service[]>) => {
            state.services = action.payload
        },
        getServicesByQueueId: (state, action: PayloadAction<number>) => {
            state.services = state.services.filter(service => service.queue_id === action.payload);
        },
    },
})

export const {getServicesByQueueId, setService} = serviceSlice.actions

export default serviceSlice.reducer




export const getServicesTC = (queue_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getServices(queue_id);
        if (result.status === 200) {
            dispatch(setService(result.data.data.result))
            dispatch(getServicesByQueueId(queue_id));
        } else {
            showNotification('Нет связи с сервером, проверьте подключение')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}