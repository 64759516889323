import axios from "axios";
import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {
    AuthState,
    UserActive,
    VerifyCode,
    Login,
    PasswordReset,
    OrganizationState
} from "./types";
import {queueApi} from "../api/queue-api";
import {showNotification, showNotificationSuccess} from "../utils/notification";
import {userDataLS} from "../utils/userDataLS";
import {setIsLoading} from "./kioskSlice";

const initialState: AuthState = {
    isLogin: false,
    userActive: null,
    isVerify: false,
    isVerifyCode: false,
    isForgotModal: false,
    resetPassword: false,
    isLoginModal: false,
    isForgotRedirect: false,
    users: [],
    organizationInfo: {} as OrganizationState
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsLogin: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        },
        setIsVerify: (state, action: PayloadAction<boolean>) => {
            state.isVerify = action.payload;
        },
        setIsForgotModal: (state, action: PayloadAction<boolean>) => {
            state.isForgotModal = action.payload;
        },
        setIsVerifyCode: (state, action: PayloadAction<boolean>) => {
            state.isVerifyCode = action.payload;
        },
        setIsResetPassword: (state, action: PayloadAction<boolean>) => {
            state.resetPassword = action.payload;
        },
        setIsLoginModal: (state, action: PayloadAction<boolean>) => {
            state.isLoginModal = action.payload;
        },
        setForgotRedirect: (state, action: PayloadAction<boolean>) => {
            state.isForgotRedirect = action.payload;
        },
        setActiveUser: (state, action: PayloadAction<UserActive>) => {
            state.userActive = action.payload;
        },
        setOrganizationData: (state, action: PayloadAction<OrganizationState>) => {
            state.organizationInfo = action.payload;
        },
        logOut: (state) => {
            state.isLogin = false;
            state.isLoginModal = false;
            state.resetPassword = false;
            state.userActive = null;
        }
    }
});

export const {
    setIsLogin,
    setActiveUser,
    logOut,
    setIsVerify,
    setIsVerifyCode,
    setIsForgotModal,
    setIsLoginModal,
    setForgotRedirect,
    setOrganizationData,
    setIsResetPassword
} = authSlice.actions;

export default authSlice.reducer;

export const onLogOutTC = () => (dispatch: Dispatch) => {
    dispatch(logOut());
    localStorage.setItem("savedPath", "");
    localStorage.setItem("auth", "");
    localStorage.setItem("current_user", "");
};

export const authTC =
    (login: string, password: string, users: UserActive[]) => async (dispatch: Dispatch) => {
        const user = users.filter(
            (el: UserActive) => el.login === login && el.password === password
        );
        if (user.length) {
            const [activeUser] = user;
            dispatch(setIsLogin(true));
            dispatch(setActiveUser(activeUser));
        }
    };

export const signUpTC = (payload: UserActive) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postClientRegistration(payload);
        if (userData.data.status) {
            dispatch(setActiveUser(payload));
            dispatch(setIsVerify(true));
            userDataLS(payload);
        } else {
            showNotification("Пользователь с такими данными уже существует, попробуйте Войти");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const signInTC = (payload: Login) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postClientLogin(payload);
        if (userData.data.status && userData.data.data.verify) {
            dispatch(setActiveUser(userData.data.data));
            axios.defaults.headers.common["Authorization"] = `Bearer ${userData.data.token}`;
            dispatch(setIsLogin(true));
            userDataLS(userData.data.data);
            return
        }

        if (userData.data.status && !userData.data.data.verify) {
            const user = {
                ...payload,
                email: userData.data.data.email,
            };
            dispatch(setIsLoginModal(true));
            dispatch(setActiveUser(user));
        } else {
            showNotification("Указаны неверные данные для входа, попробуйте еще");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const verifyTC = (payload: VerifyCode) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postClientVerifyCode(payload);
        if (userData.data.status) {
            showNotificationSuccess("Пользователь успешно зарегистрирован");
            dispatch(setIsVerify(false));
            const loginData = await queueApi.postClientLogin(payload);
            if (loginData.data.status) {
                const access_token = loginData.data.token;
                dispatch(setIsLogin(true));
                axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
                dispatch(setActiveUser(loginData.data.data));
                userDataLS(loginData.data.data);
            }
        } else {
            // проверочный код введен не верно
            dispatch(setIsVerifyCode(true));
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const resetPasswordTC = (payload: PasswordReset) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postClientResetPassword(payload);
        if (userData.data.status) {
            dispatch(setIsForgotModal(true));
            showNotificationSuccess("Код для подтверждения направлен на почту");
        } else {
            showNotification("Неверные данные, попробуйте еще");
            // можно выявить настоящие почтовые адреса
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    } finally {
        dispatch(setIsResetPassword(true));
    }
};

export const updatePasswordTC = (payload: PasswordReset) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postClientUpdatePassword(payload);
        if (userData.data.status) {
            showNotificationSuccess("Пароль изменён, попробуйте Войти");
            dispatch(setForgotRedirect(true));
            return;
        }
        if (!userData.data.status) {
            showNotification(userData.data.error);
            return;
        } else {
            showNotification("Неверные данные, попробуйте еще");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const getOrganizationTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getOrganization(id);
        if (result.status === 200) {
            dispatch(setOrganizationData(result.data.data.result[0]));
        } else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};
