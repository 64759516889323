import {createAsyncThunk, createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {
    QueueInfoState,
    QueueData, ComplexQueueData,
} from "./types";
import {showNotification} from "../utils/notification";
import {queueInfoApi} from "../api/analytics-api";

const initialState: QueueInfoState = {
    queue:null,
    queues:[],
    complexQueue: null,
    complexQueues: []
}

export const queueInfoSlice = createSlice({
    name: 'queueInfo',
    initialState,
    reducers: {
        setQueues: (state, action: PayloadAction<QueueData>) => {
            state.queue = action.payload
        }
    },
  extraReducers: builder => builder.addCase(getComplexQueueInfo.fulfilled, (state, action) => {
    state.complexQueue = action.payload
  })
})

export const {setQueues} = queueInfoSlice.actions

export default queueInfoSlice.reducer

export const getQueueInfoTC = (id:number, step:string, date_from:string, date_to:string) => async (dispatch: Dispatch) => {
    try {
        let result = await queueInfoApi.getQueue(id, step, date_from, date_to);
        if (result.status === 200) {
            dispatch(setQueues(result.data.data.queue))
        } else {
            showNotification('Нет данных, проверьте запрос')
        }
    } catch
        (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const getComplexQueueInfo = createAsyncThunk<
  ComplexQueueData,
  GetQueueInfo
>("queueInfo/getComplexQueueInfo", async (arg, { rejectWithValue }) => {
  try {
    const res = await queueInfoApi.getComplexQueue(arg);

    if (res.data.status) return res.data.data.queue;

    showNotification("Нет данных, проверьте запрос");
    return rejectWithValue(null);
  } catch (err: any) {
    showNotification("Нет связи с сервером, проверьте подключение");
    return rejectWithValue(null);
  }
});

export const queueInfoThunks = { getQueueInfoTC, getComplexQueueInfo };

export interface GetQueueInfo {
  id: number;
  step: string;
  date_from: string;
  date_to: string;
}