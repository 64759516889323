import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {queueApi} from "../api/queue-api";
import {
    ActiveTalon, MiniBoardState, UserAdd,
} from "./types";
import {showNotification} from "../utils/notification";
import {boardApi} from "../api/ws/board-api";
import {miniBoardApi} from "../api/ws/mini-board-api";
import {setRebootData} from "./rebootSlice";


const initialState: MiniBoardState = {
    error:false,
    errorCount:0,
    user: null,
    listTalon: [],
    activeTalon: null,
    organizationId: 0,
}

export const miniBoardSlice = createSlice({
    name: 'miniBoard',
    initialState,
    reducers: {
        setMiniBoard: (state, action: PayloadAction<UserAdd>) => {
            state.user = action.payload
        },
        setListTalons: (state, action: PayloadAction<ActiveTalon[]>) => {
            state.listTalon = action.payload
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload
        },
        setErrorCount: (state, action: PayloadAction<number>) => {
            state.errorCount = action.payload
        },
        setOrganizationId: (state, action: PayloadAction<number>) => {
            state.organizationId = action.payload
        },
    }
})

export const {setMiniBoard, setListTalons, setError, setErrorCount, setOrganizationId} = miniBoardSlice.actions;

export default miniBoardSlice.reducer

export const getMiniBoardTC = (queueId: number, boardId: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getMiniBoard(queueId, boardId);
        if (result.status === 200) {
            dispatch(setMiniBoard(result.data.data.user))
            //dispatch(setError(false))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        dispatch(setError(true))
    }
}

export const createConnectionTC = (queueId: number, services: string) => (dispatch: Dispatch) => {
    try {
        miniBoardApi.createConnection(queueId, services)
        miniBoardApi.subscribe(
            (talons: ActiveTalon[]) => {
                dispatch(setListTalons(talons))
            },
            () => {
                dispatch(setError(true))
                dispatch(setErrorCount(1))
            },
            () => {
                miniBoardApi.sendClient_queue_id(queueId)
                miniBoardApi.onConnectSendData(queueId, services)
                dispatch(setError(false))
            },(data)=>{
                dispatch(setRebootData(data))
            }

        )
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const destroyConnectionTC = () => (dispatch: Dispatch) => {
    try {
        boardApi.destroyConnection()
        dispatch(setError(false))
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const setErrorCountTC = (count:number) => (dispatch: Dispatch) => {
    dispatch(setErrorCount(count))
}
export const getOrganizationIdTC = (id: number) =>  async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getQueueShort(id);
        if (result.status === 200) {
            dispatch(setOrganizationId(result.data.data.result.organization_id));
        } else {
            showNotification("Нет данных, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
}