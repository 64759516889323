import { createAsyncThunk, createSlice, Dispatch } from "@reduxjs/toolkit";
import {
  SupportRequest,
  SupportRequestsState,
  SupportRequestStatuses,
} from "./types";
import { queueApi } from "../api/queue-api";
import {
  showNotification,
  showNotificationSuccess,
} from "../utils/notification";
import { RootState } from "./store";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";

const slice = createSlice({
  name: "supportRequests",
  initialState: { requests: [] } as SupportRequestsState,
  reducers: {
    clearRequests: (state) => {
      state.requests.length = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupportRequests.fulfilled, (state, action) => {
      state.requests.push(...action.payload);
    });
  },
});

const getSupportRequests = createAsyncThunk<
  SupportRequest[],
  undefined,
  { state: RootState; dispatch: Dispatch }
>(`${slice.name}/getRequests`, async (_, { getState, dispatch }) => {
  const state = getState().auth.userActive;

  try {
    const organizationID = state?.organization_id;

    if (!organizationID) {
      showNotification("Ошибка роли");

      return;
    }

    const res = await queueApi.getSupportRequests(organizationID);

    if (res.data.status) {
      dispatch(supportRequestsActions.clearRequests());

      return res.data.data.result;
    }

    showNotification("Произошла неизвестная ошибка");
  } catch (err: any) {
    showNotification("Нет связи с сервером, проверьте подключение");
  }
});

const createSupportRequest = createAsyncThunk<
  void,
  CreateSupportRequest,
  { state: RootState }
>(`${slice.name}/createRequest`, async (arg, { getState, rejectWithValue }) => {
  const state = getState().auth.userActive;

  try {
    const login = state?.login;
    const organization_id = state?.organization_id;
    const type_id = state?.role_id;

    if (!login || !organization_id || !type_id) {
      showNotification(
        !login
          ? "Такого пользователя не существует"
          : !organization_id
            ? "Такой организации не существует"
            : "Ошибка роли",
      );

      return rejectWithValue(null);
    }

    const res = await queueApi.postSupportRequest({
      ...arg,
      login,
      organization_id,
      type_id,
      id: null,
      date: new Date().toLocaleDateString(),
      status: SupportRequestStatuses.SENT,
      answer: "",
    });

    if (res.data.status) {
      showNotificationSuccess("Вы успешно создали обращение");

      return;
    }

    showNotification("Произошла неизвестная ошибка");
    return rejectWithValue(null);
  } catch (err: any) {
    showNotification("Нет связи с сервером, проверьте подключение");
    return rejectWithValue(null);
  }
});

export const supportRequestsSlice = slice.reducer;
export const supportRequestsActions = slice.actions;
export const supportRequestsThunks = {
  createSupportRequest,
  getSupportRequests,
};

//TYPES

type CreateSupportRequest = Omit<
  SupportRequest,
  "date" | "login" | "organization_id" | "type_id" | "status" | "answer" | "id"
>;
