import {Backdrop, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import s from "./LazyLoad.module.scss";

export const LazySuspense = () => {
    useEffect(() => {
        const changeClass = () => document.getElementById("loader");
        changeClass()?.setAttribute("class", s.trackingOutContract);
        const expandID = setInterval(() => {
            changeClass()?.setAttribute("class", s.trackingInExpand);
        }, 1400);
        const contractID = setInterval(() => {
            changeClass()?.setAttribute("class", s.trackingOutContract);
        }, 2800);
        const clearIntervals = () => {
            clearInterval(contractID)
            clearInterval(expandID)
        }
        setTimeout(clearIntervals, 25000)
    });
    return (
        <Backdrop open={true} style={{backgroundColor: "white", zIndex: "2001"}}>
            <CircularProgress color="inherit" style={{position: "absolute"}} />

            <span
                style={{position: "relative", color: "#272B34", left: "5px", top: "55px"}}
                id="loader">
                Загрузка...
            </span>
        </Backdrop>
    );
};
