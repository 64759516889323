import {io, Socket} from "socket.io-client";
import {HTTP_SERVER_PROTOCOL, WEBSOCKET_SERVER} from "../../constants/configConstants";
import {InfoReboot} from "../reboot-api";

export const kioskApi = {
    socket: null as null | Socket,
    createConnection ()  {
        this.socket = io(`${WEBSOCKET_SERVER}:${HTTP_SERVER_PROTOCOL}`)
    },
    subscribe(
        connectError:()=>void,
        connect:()=>void,
        setRebootData: (data: InfoReboot) => void
    ) {
        this.socket?.on('connect_error', connectError);
        this.socket?.on('connect', connect);
        this.socket?.on('isReboot', setRebootData);
    },
    destroyConnection() {
        this.socket?.disconnect()
        this.socket = null
    }
}