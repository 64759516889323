import {instance} from "./axiosConf";
import {END_POINT_GET_QUEUE_INFO, END_POINT_GET_QUEUE_INFO_COMPLEX} from "../constants/configConstants";
import {ComplexQueueData} from "../redux/types";
import {GetQueueInfo} from "../redux/queueInfoSlice";

export const queueInfoApi = {
    getQueue(id:number,step:string, dateFrom:string, dateTo:string) {
        return instance.get(`${END_POINT_GET_QUEUE_INFO}?queue_id=${id}&step=${step}&date_from=${dateFrom}&date_to=${dateTo}`);
    },

    getComplexQueue(data: GetQueueInfo) {
        const {id, step, date_from, date_to} = data

        return instance.get<GetComplexQueueDataResponse>(`${END_POINT_GET_QUEUE_INFO_COMPLEX}?queue_id=${id}&step=${step}&date_from=${date_from}&date_to=${date_to}`)
    }
}

interface GetComplexQueueDataResponse {
  status: boolean;
  data: { queue: ComplexQueueData };
}
