import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {showNotification, showNotificationSuccess} from "../utils/notification";
import {AddUsers, UserActive} from "./types";

const initialState: AddUsers = {
    data: [] as Array<UserActive & {verify: number}>
};

export const adminGetUsersSlice = createSlice({
    name: "addUsers",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<Array<UserActive & {verify: number}>>) => {
            state.data = action.payload;
        }
    }
});

export const {setUsers} = adminGetUsersSlice.actions;

export default adminGetUsersSlice.reducer;

export const getClietnsListTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getClietnsList(id);
        if (result.status === 200) {
            dispatch(setUsers(result.data.data.result));
        } else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addClientTC = (id: number, payload: UserActive) => async (dispatch: Dispatch) => {
    try {
        const userData = await queueApi.postAddAdminClient({...payload, organization_id: 1});
        if (userData.data.status) {
            showNotificationSuccess("Пользователь успешно добавлен");
            let queue = await queueApi.getClietnsList(id);
            if (queue.status === 200) {
                dispatch(setUsers(queue.data.data.result));
            }
        } else {
            showNotification("Пользователь с такими данными уже существует");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};
