import {io, Socket} from 'socket.io-client';
import {ActiveTalon} from "../../redux/types";
import {WEBSOCKET_SERVER, HTTP_SERVER_PROTOCOL} from "../../constants/configConstants";
import {InfoReboot} from "../reboot-api";

export const miniBoardApi = {
    socket: null as null | Socket,

    createConnection(queueId:number, services:string) {
        this.socket = io(`${WEBSOCKET_SERVER}:${HTTP_SERVER_PROTOCOL}`)
    },

    sendClient_queue_id(queueId:number) {
        this.socket?.emit('client_queue_id', {queueId:queueId, type:'miniBoard'})
    },

    subscribe(
        setListTalons:(talons:ActiveTalon[]) => void,
        connectError:()=>void,
        connect:()=>void,
        setRebootData: (data: InfoReboot) => void
) {
        this.socket?.on('setListTalons', setListTalons);
        this.socket?.on('connect_error', connectError);
        this.socket?.on('connect', connect);
        this.socket?.on('isReboot', setRebootData);

    },

    onConnectSendData(queueId: number, services: string) {
        this.socket?.emit('getListTalons', {queueId:queueId, services:services})
    },

    destroyConnection() {
        this.socket?.disconnect()
        this.socket = null
    }
}