import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {queueApi} from "../api/queue-api";
import {
    Board, Criterion, CriterionUnit,
    KioskAdd, Packet,
    QueueAddComplex,
    QueueComplex,
    QueueComplexState,
    QueueUpdate,
    Service,
    User
} from "./types";
import {showNotification} from "../utils/notification";

const initialState: QueueComplexState = {
    queueListComplex: [],
}

export const queueComplexSlice = createSlice({
    name: 'queueComplex',
    initialState,
    reducers: {
        setQueuesComplex: (state, action: PayloadAction<QueueComplex[]>) => {
            state.queueListComplex = action.payload
        },
        deleteQueueComplex: (state, action: PayloadAction<number>) => {
            state.queueListComplex = state.queueListComplex.filter((el: QueueComplex) => el.id !== action.payload)
        },
    },
})

export const {setQueuesComplex, deleteQueueComplex} = queueComplexSlice.actions;

export default queueComplexSlice.reducer

export const getQueueComplexTC = (organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getQueueComplex(organization_id);
        if (result.status === 200) {
            dispatch(setQueuesComplex(result.data.data))
        } else {
            showNotification('Нет данных, проверьте запрос')
        }
    } catch
        (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addQueueComplexTC = (data: QueueAddComplex, organization_id:number) => async (dispatch: Dispatch) => {
    const newData = {
        ...data,
        packets: data.packets.map((el) => {
            return {
                title: el.title,
                services: el.services.join(),
                message:el.message,
                next_packet_id:el.next_packet_id,
                services_stop_list:el.services_stop_list
            }
        }),
        users: data.users.map((el) => {
            return {
                name: el.name,
                place: el.place,
                status: el.status,
                services: el.services.join(),
                isActive: el.isActive,
                dop_service:el.dop_service,
                is_window:el.is_window,
                role:el.role,
                service_dop:el.service_dop,
                two_blocks:el.two_blocks,
                isComplex:true
            }
        }),
        boards: data.boards.map((el) => {
            return {
                title: el.title,
                services: el.services.join(),
                is_animation:el.is_animation
            }
        }),
        kiosks: data.kiosks.map((el) => {
            return {
                title: el.title,
                services: el.services.join()
            }
        })
    }

    try {
        let result = await queueApi.postQueueComplex(newData);
        if (result) {
            let queue = await queueApi.getQueueComplex(organization_id);
            if (queue.status === 200) {
                dispatch(setQueuesComplex(queue.data.data))
            } else {
                showNotification('Нет данных, проверьте запрос')
            }
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteQueueComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.deleteQueueComplex(id);
        if (result.status === 200) {
            dispatch(deleteQueueComplex(id))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateQueueComplexTC = (queue: QueueUpdate, organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.putQueueComplex(queue);
        if (result) {
            let queue = await queueApi.getQueueComplex(organization_id);
            if (queue.status === 200) {
                dispatch(setQueuesComplex(queue.data.data))
            } else {
                showNotification('Нет данных, проверьте запрос')
            }
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateServiceComplexTC = (data: Service) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putService(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updatePacketComplexTC = (data: Packet) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putPacket(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateCriterionComplexTC = (data: Criterion) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putCriterion(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateCriterionUnitComplexTC = (unit: CriterionUnit) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putCriterionUnit(unit);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateUserComplexTC = (data: User) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putUser(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateBoardComplexTC = (data: Board) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putBoard(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateKioskComplexTC = (data: KioskAdd) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putKiosk(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteServiceComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteService(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deletePacketComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deletePacket(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteCriterionComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteCriterion(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteCriterionUnitComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteCriterionUnit(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteUserComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteUser(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteBoardComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteBoard(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const deleteKioskComplexTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteKiosk(id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addServiceComplexTC = (data:Service, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postService(data, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addUserComplexTC = (data:User, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postUser({...data, isComplex:true}, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addBoardComplexTC = (data:Board, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postBoard(data, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addPacketComplexTC = (data:Packet, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postPacket(data, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addCriterionComplexTC = (data:Criterion, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postCriterion(data, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}
export const addCriterionUnitComplexTC = (data:CriterionUnit) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postCriterionUnit(data);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addKioskComplexTC = (data:KioskAdd, queue_id:number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postKiosk(data, queue_id);
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const updateDataQueuesTC = (organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let queue = await queueApi.getQueueComplex(organization_id);
        if (queue.status === 200) {
            dispatch(setQueuesComplex(queue.data.data))
        } else {
            showNotification('Нет данных, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

