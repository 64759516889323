import sts from "./App.module.scss";
import {Router} from "./components/router/Router";
import {ReactNotifications} from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

function App() {

    return (
        <div className={sts.App}>
            <ReactNotifications />
            <Router />
        </div>
    );
}

export default App;
