import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  OrganizationState,
  SuperAdminState,
  Client,
  SupportRequest,
} from "./types";
import { queueApi } from "../api/queue-api";
import { showNotification } from "../utils/notification";

const slice = createSlice({
  name: "superAdmin",
  initialState: {
    requests: [],
    organizations: [],
    users: [],
  } as SuperAdminState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.organizations.length = 0;
        state.organizations.push(...action.payload);
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.users.length = 0;
        state.users.push(...action.payload);
      })
      .addCase(getSupportMessages.fulfilled, (state, action) => {
        state.requests.length = 0;
        state.requests.push(...action.payload);
      }),
});

const getOrganizations = createAsyncThunk<OrganizationState[], undefined>(
  "superAdmin/getOrganizations",
  async (_, { rejectWithValue }) => {
    try {
      const res = await queueApi.getOrganizations();

      if (res.data.status) return res.data.data.result;

      showNotification("Что-то пошло не так");
      return rejectWithValue(null);
    } catch (error: any) {
      showNotification("Нет связи с сервером, проверьте подключение");
      return rejectWithValue(null);
    }
  },
);

const getAllClients = createAsyncThunk<Client[], undefined>(
  "superAdmin/getAllClients",
  async (_, { rejectWithValue }) => {
    try {
      const res = await queueApi.getAllClients();

      if (res.data.status) return res.data.data.result;

      showNotification("Что-то пошло не так");
      return rejectWithValue(null);
    } catch (error: any) {
      showNotification("Нет связи с сервером, проверьте подключение");
      return rejectWithValue(null);
    }
  },
);

const getSupportMessages = createAsyncThunk<SupportRequest[], undefined>(
  "superAdmin/getSupportMessages",
  async (_, { rejectWithValue }) => {
    try {
      const res = await queueApi.getSupportMessages();

      if (res.data.status) return res.data.data.result;

      showNotification("Что-то пошло не так");
      return rejectWithValue(null);
    } catch (error: any) {
      showNotification("Нет связи с сервером, проверьте подключение");
      return rejectWithValue(null);
    }
  },
);

export const superAdminSlice = slice.reducer;
export const superAdminThunks = {
  getOrganizations,
  getAllClients,
  getSupportMessages,
};
