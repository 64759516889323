import {io, Socket} from 'socket.io-client';
import {HTTP_SERVER_PROTOCOL, WEBSOCKET_SERVER} from "../../constants/configConstants";
import {ActiveTalon} from "../../redux/types";

export const myQueueClientApi = {
    socket: null as null | Socket,

    createConnection() {
        this.socket = io(`${WEBSOCKET_SERVER}:${HTTP_SERVER_PROTOCOL}`)
    },

    subscribe(
        connectError: () => void,
        connect: () => void,
        errorMessage: (errorMessage:string) => void,
        updateTalons: (talons: ActiveTalon[]) =>void
    ) {
        this.socket?.on('connect_error', connectError);
        this.socket?.on('connect', connect);
        this.socket?.on('errorMessageChangeStatusTalon', errorMessage);
        this.socket?.on('setListTalons', updateTalons)
    },

    setStatusTalon(queueId: number, talonId: number, userId: number, is_window: boolean, place: number, status_id: number) {
        this.socket?.emit('setStatusTalon', {
            queueId: queueId,
            talonId: talonId,
            userId: userId,
            is_window: is_window,
            place: place,
            status_id: status_id
        })
    },

    changeStatusTalon(queue_id: number, talon_id: number, status_id: number) {
        this.socket?.emit('changeStatusTalonStop', {queue_id: queue_id, talon_id: talon_id, status_id: status_id})
    },

    sendClient_queue_id(queueId: number) {
        this.socket?.emit('client_queue_id', {queueId: queueId, type: 'myQueueClient'})
    },

    destroyConnection() {
        this.socket?.disconnect();
        this.socket = null
    }
};