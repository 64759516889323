import {io, Socket} from 'socket.io-client';
import {ActiveTalon, Packet, Service} from "../../redux/types";
import {WEBSOCKET_SERVER, HTTP_SERVER_PROTOCOL} from "../../constants/configConstants";
import {InfoReboot} from "../reboot-api";

export const userApi = {
    socket: null as null | Socket,

    createConnection(queueId: number, services: string) {
        this.socket = io(`${WEBSOCKET_SERVER}:${HTTP_SERVER_PROTOCOL}`)
        this.socket?.emit('getListTalons', {queueId: queueId, services: services})
    },

    subscribe(
        setListTalons: (talons: ActiveTalon[]) => void,
        connectError: () => void,
        connect: () => void,
        setRebootData: (data: InfoReboot) => void
    ) {
        this.socket?.on('setListTalons', setListTalons);
        this.socket?.on('connect_error', connectError);
        this.socket?.on('connect', connect);
        this.socket?.on('isReboot', setRebootData);
    },

    setStatusTalon(queueId: number, talonId: number, userId: number, is_window: boolean, place: string, status_id: number) {
        this.socket?.emit('setStatusTalon', {
            queueId: queueId,
            talonId: talonId,
            userId: userId,
            is_window: is_window,
            place: place,
            status_id: status_id
        })
    },

    onConnectSendData(queueId: number, services: string) {
        this.socket?.emit('getListTalons', {queueId: queueId, services: services})
    },

    changeStatusTalon(queue_id: number, talon_id: number, status_id: number) {
        this.socket?.emit('changeStatusTalon', {queue_id: queue_id, talon_id: talon_id, status_id: status_id})
    },

    changeStatusTalonComplex(queue_id: number, talon_id: number, status_id: number, user_services:string|undefined, delay:number) {
        this.socket?.emit('changeStatusTalonComplex', {queue_id: queue_id, talon_id: talon_id, status_id: status_id, user_services: user_services, delay: delay})
    },

    changeServiceTalon(talon: ActiveTalon, service: Service, status_id: number) {
        this.socket?.emit('changeServiceTalon', {talon: talon, service: service, status_id: status_id})
    },

    changePacketTalon(talon: ActiveTalon, packet: Packet, status_id: number) {
        this.socket?.emit('changePacketTalon', {talon: talon, packet: packet, status_id: status_id})
    },

    changeRoleTalon(talon: ActiveTalon, role: number, fio: string, service_dop: number, date: string) {
        this.socket?.emit('changeRoleTalon', {talon: talon, role: role, fio: fio, service_dop: service_dop, date: date})
    },

    sendClient_queue_id(queueId: number) {
        this.socket?.emit('client_queue_id', {queueId: queueId, type: 'operator'})
    },

    addServiceInTalonComplex(queue_id: number, talon_id: number, internal_id: string) {
        this.socket?.emit('addServiceInTalonComplex', {queue_id: queue_id, talon_id: talon_id, internal_id: internal_id})
    },

    addReturnServiceInTalonComplex(queue_id: number, talon_id: number, user_services: string, user_id:number) {
        this.socket?.emit('addReturnServiceInTalonComplex', {queue_id: queue_id, talon_id: talon_id, user_services: user_services, user_id: user_id})
    },

    destroyConnection() {
        this.socket?.disconnect()
        this.socket = null
    }
}