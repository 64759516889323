import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {EncryptInfoReboot, InfoReboot, rebootApi} from '../api/reboot-api';
import {showNotification, showNotificationSuccess} from "../utils/notification";

interface RebootState {
    isLoading: boolean;
    error: string | null;
    rebootData: InfoReboot | null;
}

const initialState: RebootState = {
    isLoading: false,
    error: null,
    rebootData:null,
};

const rebootSlice = createSlice({
    name: 'reboot',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        postRebootSuccess(state) {
            state.isLoading = false;
            state.error = null;
        },
        postRebootFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setRebootData(state, action) {
            state.rebootData = action.payload;
        }
    },
});

export const postRebootTC = (data: EncryptInfoReboot) => async (dispatch: Dispatch) => {
    try {
        dispatch(startLoading());
        const response = await rebootApi.postReload(data);
        if (response.status === 200) {
            dispatch(postRebootSuccess());
            showNotificationSuccess("Устройство успешно перезагружено")
        } else {
            dispatch(postRebootFailure(response.data.message));
            showNotification("Произошла ошибка при перезагрузке устройства")
        }
    } catch (error:any) {
        dispatch(postRebootFailure(error.message));
        showNotification("Неизвестная ошибка")
    }
};

export const { startLoading, postRebootSuccess, postRebootFailure, setRebootData } = rebootSlice.actions;
export default rebootSlice.reducer;
