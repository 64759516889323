import { useEffect } from "react";
import { setActiveUser, setIsLogin } from "../redux/authSlice";
import { useAppDispatch } from "../redux/hooks";
import { UserActive } from "../redux/types";
import { userDataLS } from "./userDataLS";

export const useIsAuth = (auth: boolean) => {
    const dispatch = useAppDispatch()
    useEffect(()=>{
        if (auth) {
            dispatch(setIsLogin(true));
            dispatch(setActiveUser(userDataLS({} as UserActive, true)));
            return
        }
    },[])
};
