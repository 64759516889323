import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {showNotification, showNotificationSuccess} from "../utils/notification";
import {Colors} from "../constants/styleConstants";
import {queueApi} from "../api/queue-api";

const initialState: Colors = {
    organization_id: '',
    leftbarbackground: '',
    leftbordercolor: '',
    titlecolor: '',
    datecolor: '',
    rightbgfirstcolor: '',
    rightbglastcolor: '',
    waitinglibackground: '',
    waitinglicolor: '',
    ticketscolor: '',
    kioskbgcolor: '',
    kiosknamecolor: '',
    kiosktitlecolor: '',
    buttonbgfirstcolor: '',
    buttonbglastcolor: '',
    buttontextcolor: '',
    buttonafterbgcolor: '',
};

export const colorsSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {
        setColors: (state, action: PayloadAction<Colors>) => {
            const colors = action.payload;

            state.organization_id = colors.organization_id;
            state.leftbarbackground = colors.leftbarbackground;
            state.leftbordercolor = colors.leftbordercolor;
            state.titlecolor = colors.titlecolor;
            state.datecolor = colors.datecolor;
            state.rightbgfirstcolor = colors.rightbgfirstcolor;
            state.rightbglastcolor = colors.rightbglastcolor;
            state.waitinglibackground = colors.waitinglibackground;
            state.waitinglicolor = colors.waitinglicolor;
            state.ticketscolor = colors.ticketscolor;
            state.kioskbgcolor = colors.kioskbgcolor;
            state.kiosknamecolor = colors.kiosknamecolor;
            state.kiosktitlecolor = colors.kiosktitlecolor;
            state.buttonbgfirstcolor = colors.buttonbgfirstcolor;
            state.buttonbglastcolor = colors.buttonbglastcolor;
            state.buttontextcolor = colors.buttontextcolor;
            state.buttonafterbgcolor = colors.buttonafterbgcolor;
        },
    },

})

export const {setColors} = colorsSlice.actions
export default colorsSlice.reducer
export const getOrganizationColorsTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getColorSchema(id);
        if (result.status === 200) {
            dispatch(setColors(result.data.data.result))
        } else {
            showNotification('Ошибка при обновлении цветовой схемы');
        }
    } catch
        (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}
export const updateOrganizationColorsTC = (organization_id: string, colors: Colors) => async (dispatch: Dispatch) => {
    try {
        const updatedColors = {...colors, organization_id: organization_id};
        let result = await queueApi.putColorSchema(updatedColors);
        if (result.status === 200) {
            showNotificationSuccess('Цветовая схема успешно обновлена');
        } else {
            showNotification('Ошибка при обновлении цветовой схемы');
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение');
    }
};