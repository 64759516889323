import {instance} from "./axiosConf";
import {END_POINT_POST_RELOAD} from "../constants/configConstants";

export type InfoReboot = {
    type_id?: number;
    isReboot?: boolean;
    organization_ids?: string;
    queue_ids?: string;
    kiosk_ids?: string;
    board_ids?: string;
    board_mini_ids?: string;
    operator_ids?: string;
    date_time:null
};

export type EncryptInfoReboot = {info: string}

export const rebootApi = {
    postReload(data:EncryptInfoReboot){
        return instance.post(`${END_POINT_POST_RELOAD}`, data)
    }
}