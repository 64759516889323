import React from "react";

export const LazyAdmin = React.lazy(() => import("../../pages/Admin/Admin"));
export const LazyKiosk = React.lazy(() => import("../../pages/Kiosk/Kiosk"));
export const LazyMain = React.lazy(() => import("../../pages/Main/Main"));
export const LazyOperator = React.lazy(() => import("../../pages/Operator/Operator"));
export const LazyTabloMini = React.lazy(() => import("../../pages/TabloMini/TabloMini"));
export const LazyBoard = React.lazy(() => import("../../pages/Board/Board"));
export const LazyVrach = React.lazy(() => import("../../pages/Vrach/Vrach"));
export const LazyNoMatch = React.lazy(() => import("../Nomatch/NoMatch"));
export const LazyKioskComplex = React.lazy(() => import("../../pages/KioskComplex/KioskComplex"));
export const LazyBoardComplex = React.lazy(() => import("../../pages/BoardComplex/BoardComplex"));
export const LazyMyQueue = React.lazy(() => import("../../pages/MyQueue/MyQueue"));
export const LazyForbidden = React.lazy(() => import("../Forbidden/Forbidden"));