import {configureStore} from '@reduxjs/toolkit'
import queueReducer from "./queueSlice";
import videosReducer from "./videosSlice";
import queueComplexReducer from "./queueComplexSlice";
import boardSlice from "./boardSlice";
import kioskSlice from "./kioskSlice";
import userSlice from "./userSlice";
import miniBoardSlice from "./miniBoardSlice";
import authSlice from "./authSlice";
import analyticsSlice from "./queueInfoSlice";
import myQueueClientSlice from "./myQueueClientSlice";
import packetSlice from "./packetSlice";
import criterionReducer from "./criterionSlice";

import serviceSlice from "./serviceSlice";
import talonsSlice from "./talonsSlice";
import logoSlice from './logoSlice';
import organizationSlice from "./organizationSlice";
import adminGetUsersSlice from './adminGetUsersSlice';
import colorsSlice from "./colorsSlice";
import rebootSlice from "./rebootSlice";
import {supportRequestsSlice} from "./supportRequestsSlice";
import {superAdminSlice} from "./superAdminSlice";

export const store = configureStore({
    reducer: {
        queue: queueReducer,
        queueComplex: queueComplexReducer,
        board: boardSlice,
        miniBoard: miniBoardSlice,
        kiosk: kioskSlice,
        logo: logoSlice,
        user: userSlice,
        auth: authSlice,
        analytics: analyticsSlice,
        myQueueClient: myQueueClientSlice,
        packet: packetSlice,
        talons: talonsSlice,
        services: serviceSlice,
        videos: videosReducer,
        organization: organizationSlice,
        adminGetUsers: adminGetUsersSlice,
        criterion: criterionReducer,
        colors: colorsSlice,
        reboot:rebootSlice,
        supportRequests: supportRequestsSlice,
        superAdmin: superAdminSlice
    },
    // middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware()
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// export const useAppDispatch: () => AppDispatch = useDispatch 