import {Store} from "react-notifications-component";

export const showNotification = (message:string) => {
    Store.addNotification({
        message,
        type: "danger",
        container: "bottom-right",
        dismiss: { duration: 4000 },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"]
    });
}

export const showNotificationInfo = (message:string) => {
    Store.addNotification({
        message,
        type: "info",
        container: "bottom-right",
        dismiss: { duration: 4000 },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"]
    });
}

export const showNotificationSuccess = (message:string) => {
    Store.addNotification({
        message,
        type: "success",
        container: "bottom-right",
        dismiss: { duration: 4000 },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"]
    });
}