import {UserActive} from "../redux/types";

export const userDataLS = (data: UserActive | null, value: boolean = false) => {
    if (value) {
        return JSON.parse(`${localStorage.getItem("current_user")}`);

    }
    const dataToLS = {...data, password: ""};
    localStorage.setItem("current_user", JSON.stringify(dataToLS));
    localStorage.setItem("auth", 'true');
};
