import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {
    Board,
    BoardsByQueusState,
    KioskAdd,
    Queue,
    QueueAdd,
    QueuesForVideos,
    QueueShort,
    QueueState,
    QueueUpdate,
    Service,
    User
} from "./types";
import {showNotification} from "../utils/notification";
import {setQueuesComplex} from "./queueComplexSlice";

const initialState: QueueState = {
    queueList: [],
    servicesList: [],
    queueListForVideos: [] as QueuesForVideos[],
    queuesListByQueu: [] as BoardsByQueusState[],
    queue:null
};

export const queueSlice = createSlice({
    name: "queue",
    initialState,
    reducers: {
        setQueues: (state, action: PayloadAction<Queue[]>) => {
            state.queueList = action.payload;
        },
        setQueue: (state, action: PayloadAction<QueueShort>) => {
            state.queue = action.payload;
        },
        setQueuesList: (state, action: PayloadAction<QueuesForVideos[]>) => {
            state.queueListForVideos = action.payload;
        },
        setQueuesListByQueu: (state, action: PayloadAction<BoardsByQueusState[]>) => {
            state.queuesListByQueu = action.payload;
        },
        setQueuesListByQueuChecked: (state, action: PayloadAction<{board_id: number, video_id: number}>) => {
            if (state.queuesListByQueu.find(el=>el.id === action.payload.board_id)?.videos_ids && state.queuesListByQueu.find(el=>el.id === action.payload.board_id)?.videos_ids?.includes(action.payload.video_id + '')) {
                state.queuesListByQueu = state.queuesListByQueu.map(el=>{
                    return el.id === action.payload.board_id ? {...el, videos_ids: el.videos_ids.filter(el=> el !== action.payload.video_id + "")} : el
                })
                return
            }
            state.queuesListByQueu = state.queuesListByQueu.map(el=>{
                const updatedVideosID = action.payload.video_id + ""
                if (el.videos_ids) {
                    return el.id === action.payload.board_id ? {...el, videos_ids: [...el.videos_ids, updatedVideosID]} : el
                }
                return el.id === action.payload.board_id ? {...el, videos_ids: [updatedVideosID]} : el
            })
        },
        deleteQueue: (state, action: PayloadAction<number>) => {
            state.queueList = state.queueList.filter((el: Queue) => el.id !== action.payload);
        },
        setServicesList: (state, action: PayloadAction<Service[]>) => {
            state.servicesList = action.payload;
        }
    }
});

export const {setQueues, setQueue, deleteQueue, setServicesList, setQueuesList, setQueuesListByQueu, setQueuesListByQueuChecked} = queueSlice.actions;

export default queueSlice.reducer;

export const getQueueTC = (organization_id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getQueue(organization_id);
        if (result.status === 200) {
            dispatch(setQueues(result.data.data));
        } else {
            showNotification("Нет данных, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const getQueueShortTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getQueueShort(id);
        if (result.status === 200) {
            dispatch(setQueue(result.data.data.result));
        } else {
            showNotification("Нет данных, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const getQueueListTC = (organization_id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getQueueList(organization_id);
        if (result.status === 200) {
            dispatch(setQueuesList(result.data.data.result));
        } else {
            showNotification("Нет данных, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addQueueTC =
    (data: QueueAdd, organization_id: number) => async (dispatch: Dispatch) => {
        const newData = {
            ...data,
            users: data.users.map((el) => {
                return {
                    name: el.name,
                    place: el.place,
                    status: el.status,
                    services: el.services.join(),
                    isActive: el.isActive,
                    dop_service: el.dop_service,
                    is_window: el.is_window,
                    role: el.role,
                    service_dop: el.service_dop,
                    two_blocks: el.two_blocks
                };
            }),
            boards: data.boards.map((el) => {
                return {
                    title: el.title,
                    services: el.services.join(),
                    is_animation: el.is_animation
                };
            }),
            kiosks: data.kiosks.map((el) => {
                return {
                    title: el.title,
                    services: el.services.join()
                };
            })
        };

        try {
            let result = await queueApi.postQueue(newData);
            if (result) {
                let queue = await queueApi.getQueue(organization_id);
                if (queue.status === 200) {
                    dispatch(setQueues(queue.data.data));
                } else {
                    showNotification("Нет данных, проверьте запрос");
                }
            } else {
                showNotification("Не верные данные, проверьте запрос");
            }
        } catch (error: any) {
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };

export const deleteQueueTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.deleteQueue(id);
        if (result.status === 200) {
            dispatch(deleteQueue(id));
        } else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateQueueTC =
    (queue: QueueUpdate, organization_id: number) => async (dispatch: Dispatch) => {
        try {
            let result = await queueApi.putQueue(queue);
            if (result) {
                let queue = await queueApi.getQueue(organization_id);
                if (queue.status === 200) {
                    dispatch(setQueues(queue.data.data));
                } else {
                    showNotification("Нет данных, проверьте запрос");
                }
            } else {
                showNotification("Не верные данные, проверьте запрос");
            }
        } catch (error: any) {
            showNotification("Нет связи с сервером, проверьте подключение");
        }
    };

export const updateServiceTC = (data: Service) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putService(data);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateUserTC = (data: User) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putUser(data);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateBoardTC = (data: Board) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putBoard(data);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateKioskTC = (data: KioskAdd) => async (dispatch: Dispatch) => {
    try {
        await queueApi.putKiosk(data);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const deleteServiceTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteService(id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const deleteUserTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteUser(id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const deleteBoardTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteBoard(id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const deleteKioskTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.deleteKiosk(id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addServiceTC = (data: Service, queue_id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postService(data, queue_id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addUserTC = (data: User, queue_id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postUser(data, queue_id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addBoardTC = (data: Board, queue_id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postBoard(data, queue_id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const addKioskTC = (data: KioskAdd, queue_id: number) => async (dispatch: Dispatch) => {
    try {
        await queueApi.postKiosk(data, queue_id);
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const getServicesListTC = (organization_id: number) => async (dispatch: Dispatch) => {
    try {
        const result = await queueApi.getServicesByOrganization(organization_id);
        if (result.status === 200) {
            dispatch(setServicesList(result.data.data.result));
        } else {
            showNotification("Не верные данные, проверьте запрос");
        }
    } catch (error: any) {
        showNotification("Нет связи с сервером, проверьте подключение");
    }
};

export const updateQueuesTC = (organization_id:number) => async (dispatch: Dispatch) => {
    try {
        let queue = await queueApi.getQueue(organization_id);
        if (queue.status === 200) {
            dispatch(setQueues(queue.data.data))
        } else {
            showNotification('Нет данных, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}
