import s from "./LazyLoad.module.scss";
import {LazySuspense} from "./LazySuspense";

export const ErrorBoundaryFallBack = () => {
    return (
        <div className={s.glass}>
            <div style={{position: "absolute"}}>
                <LazySuspense />
            </div>
        </div>
    );
};
